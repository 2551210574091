import { useKeycloak } from '@react-keycloak/web';
import { lazy, Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Navigate as Redirect,
  Route,
  Routes as Switch,
} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import NavBarTop from '../shared/components/menu-top/nav-bar-top';
import SwitchLang from '../shared/components/switch-lang';
import Loading from '../shared/components/loading';
import { useGetMeQuery } from '../shared/services/meApi';
import LoadingsRouter from './loadings-router';
import NotAuthenticated from '../pages/not-authenticated';
import { Slide, ToastContainer } from 'react-toastify';
import TenantMasterInfoPage from '../pages/TenantMasterInfoPage';
import ProtectedRoute from './ProtectedRoute';
import * as KeyCloackService from '../shared/services/keycloack';

const CreateAccountPage = lazy(() => import('../pages/CreateAccountPage'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const TemplateListPage = lazy(() => import('../pages/TemplateListPage'));
const TemplateEditPage = lazy(() => import('../pages/TemplateEditPage'));
const TenantsPage = lazy(() => import('../pages/TenantsPage'));
const TenantInfoPage = lazy(() => import('../pages/TenantInfoPage'));
const InvoicesListPage = lazy(() => import('../pages/InvoicesListPage'));

export const AppRouter = () => {
  const { initialized, keycloak } = useKeycloak();
  const [skipQuery, setSkipQuery] = useState<boolean>(true);
  const {
    data: user,
    isFetching: userFetching,
    isSuccess,
  } = useGetMeQuery(undefined, { skip: skipQuery });

  useEffect(() => {
    if (initialized && keycloak?.authenticated) {
      setSkipQuery(false);
    }
  }, [initialized, keycloak]);

  useEffect(() => {
    // Logout if user doesn't have required roles
    if (user) {
      console.table(user);
      console.log(user.roles);
      if (
        !user.roles.includes('support-user') &&
        !user.roles.includes('intrum-agent') &&
        !user.roles.includes('admin-user')
      ) {
        KeyCloackService.logout(keycloak);
      }
    }
  });

  if (!initialized || (keycloak?.authenticated && !isSuccess)) {
    return (
      <LoadingsRouter
        masterFetched={userFetching}
        initialized={initialized}
        keycloak={keycloak}
      />
    );
  }
  return (
    <Router>
      {keycloak?.authenticated && (
        <Suspense fallback={'...'}>
          <NavBarTop />
        </Suspense>
      )}
      <Container>
        <Suspense fallback={<Loading message="loading.page" />}>
          <Switch>
            <Route
              path="/tenants"
              element={
                <ProtectedRoute>
                  <TenantsPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard user={user} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tenants/:tenantId"
              element={
                <ProtectedRoute>
                  <TenantInfoPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tenants/:tenantId/master/:master"
              element={
                <ProtectedRoute>
                  <TenantMasterInfoPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tenants/new"
              element={
                <ProtectedRoute>
                  <CreateAccountPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/templates"
              element={
                <ProtectedRoute>
                  <TemplateListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/templates/:templateCode"
              element={
                <ProtectedRoute>
                  <TemplateEditPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/invoices"
              element={
                <ProtectedRoute>
                  <InvoicesListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/unauthorized"
              element={
                keycloak?.authenticated ? (
                  <Redirect to="/tenants" />
                ) : (
                  NotAuthenticated
                )
              }
            />
            <Route path="/" element={<Redirect to="/tenants" />} />
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <Redirect to="/tenants" />
                </ProtectedRoute>
              }
            />
          </Switch>
        </Suspense>
        <SwitchLang user={user} />
      </Container>
      <ToastContainer
        role="alert"
        limit={3}
        pauseOnFocusLoss
        transition={Slide}
      />
    </Router>
  );
};
